import React from "react"
import { Layout, Title } from "../components/index"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"
import "../components/book.scss"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types" //for images i think

export default ({ pageContext: { booksData } }) => {
  const imgsData = useStaticQuery(graphql`
    query MyQuery {
      allFile(filter: { extension: { regex: "/(png)/" } }) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                aspectRatio
                base64
                src
                srcSet
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Title title="Books" subtitle="by our authors"></Title>
      <div className="books-wrapper">
        {booksData.map((book, cnt) => (
          <div className="book">
            <Link to={`/${book.node.slug}`}>
              <div className="book-cover-wrapper-wrapper">
                <div className="book-cover-wrapper">
                  <Img
                    className="fluid-img"
                    fluid={book.node.cover.fluid}
                    alt={book.node.title}
                  />
                </div>
              </div>
              <div className="book-title-and-description">
                <h2 className="book-title">{book.node.title}</h2>
                <p className="book-description">
                  {book.node.longDescription.json.content[0].content[0].value.substring(
                    0,
                    200
                  )}
                  ...
                </p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </Layout>
  )
}
